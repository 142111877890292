import React from 'react';
import './Lineup.scss';
import lineup from './Images/Line-up.webp';
import lineupmobile from './Images/Line-up-mobile.webp';

const Lineup = () => {
  return (
    <>
      <img className="lineup" src={lineup} alt="lineup" />
      <img className="lineup-mobile" src={lineupmobile} alt="lineup" />
    </>
  );
};

export default Lineup;
