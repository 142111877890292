import React from 'react';
import './Previous.scss';
import otter4 from './Images/IMG_0085.webp';
import otter3 from './Images/otter3.webp';

const Previous = () => {
  return (
    <div className="previous">
      <div className="edition">
        <img src={otter3} alt="Otterdance 3" />
        <h3>OTTERDANCE 3 - ROTTERDAM</h3>
      </div>
      <div className="edition">
        <img src={otter4} alt="Otterdance 4" />
        <h3>OTTERDANCE 4 - AMSTERDAM</h3>
      </div>
    </div>
  );
};

export default Previous;
