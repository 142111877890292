import { useEffect } from 'react';
import './Schedule.scss';
import Textbox from '../textbox/Textbox';

const Schedule = () => {
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('visible');
          }
        });
      },
      { threshold: 0.1 }
    );

    const scheduleItems = document.querySelectorAll('.textbox');
    scheduleItems.forEach((item) => observer.observe(item));

    return () => observer.disconnect();
  }, []);
  return (
    <div className="schedule">
      <Textbox
        title="1900 - 2100: ARKIFEL"
        content={[
          `Arkifel brings us a set full of electro and bass house guaranteed to get your booty moving! They've played at Otterdance, BunkerBeats, Alfurnative, and hacker events around Amsterdam. Arkifel also produces house music and chiptunes, so you might hear a few fresh tracks too. You don’t want to miss it!`,
        ]}
        // WARNING: add correct link
        link={{
          href: 'https://tube.tchncs.de/w/wnh4wFcBAx1KA27xa65SJB',
          title: 'PEERTUBE',
        }}
      />
      <Textbox
        title="2100 - 2300: VEGAZ"
        content={[
          `Vegaz is  known for his previous work at NFC, Otterdance and Pawske party! With his various styles of Drum & Bass, ranging from Belgian jump all the way to some calmer liquids, he is sure to get all of you swinging!`,
        ]}
        // WARNING: add correct link
        link={{
          href: 'https://soundcloud.com/dj_vegaz',
          title: 'SOUNDCLOUD',
        }}
      />
      <Textbox
        title="2300 - 0100: STOEIPOES"
        // WARNING: add correct content
        content={[
          `Stoeipoes gives popular hits a powerful, energetic twist, blending melodic vocals with heavy kicks. His unique remixes set every dance floor on fire. Prepare yourself for a mind-bending journey as Stoeipoes takes the decks at this furry-themed rave—where bass meets fur, and every beat unites the crowd in a euphoric frenzy that you’ll feel deep in your chest!`,
        ]}
        // WARNING: add correct link
        link={{
          href: 'https://soundcloud.com/stoeipoeske',
          title: 'SOUNDCLOUD',
        }}
      />
    </div>
  );
};

export default Schedule;
