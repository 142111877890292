import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import Homepage from './homepage/Homepage';
import Tickets from './tickets/Tickets';
import Rules from './rules/Rules';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const router = createBrowserRouter([
  {
    path: '/',
    element: <Homepage />,
  },
  {
    path: '/tickets',
    element: <Tickets />,
  },
  {
    path: '/rules',
    element: <Rules />,
  },
]);

root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
