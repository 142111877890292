import React from 'react';
import './Textbox.scss';

interface Link {
  href: string;
  title: string;
}

interface TextboxProps {
  title?: string;
  content: string | string[];
  link?: Link;
}

const Textbox: React.FC<TextboxProps> = ({ title, content, link }) => {
  return (
    <div className="textbox">
      {title && <h3>{title}</h3>}
      {Array.isArray(content) ? (
        content.map((text, index) => (
          <p key={index} dangerouslySetInnerHTML={{ __html: text }} />
        ))
      ) : (
        <p dangerouslySetInnerHTML={{ __html: content }} />
      )}
      {link && (
        <a href={link.href} target="_blank" rel="noopener noreferrer">
          {link.title}
        </a>
      )}
    </div>
  );
};

export default Textbox;
