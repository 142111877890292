import React, { useEffect } from 'react';
import './Infocards.scss';
import akhnaton from './Images/akhnaton1.webp';
import bluesky from './Images/bluesky.png';
import mastodon from './Images/mastodon.png';
import telegram from './Images/telegram.png';

const Infocards = () => {
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('visible');
          }
        });
      },
      { threshold: 0.1 }
    );

    const cards = document.querySelectorAll('.card');
    cards.forEach((card) => observer.observe(card));

    return () => observer.disconnect();
  }, []);

  return (
    <div className="cards">
      <div className="card">
        <h2>WHAT</h2>
        <p>Get ready for the next Otterdance!</p>
        <p>
          Step into a world where your fursona comes alive and the dance floor
          is your playground. Whether you&apos;re a seasoned raver or new to the
          scene, this is your chance to unleash your wild side in a celebration
          of music, color, and community.
        </p>
        <p>
          Join 200 furries as we dance to hot electronic beats, spun by DJs who
          know how to keep the energy going. This is your moment to shine,
          connect with fellow furries, and make lasting memories.
        </p>
      </div>
      <div className="card">
        <h2>WHERE</h2>
        <p>
          Akhnaton is within an easy walk from Amsterdam Centraal and has a
          fascinating history. Otterdance 5 is located at{' '}
          <a href="https://maps.app.goo.gl/Qc44yJ7MQL6TCEGK9" target="_new">
            Nieuwezijds Kolk 25, 1012 PV Amsterdam
          </a>
          .
        </p>
        <img src={akhnaton} alt="Akhnaton" />
      </div>
      <div className="card">
        <h2>WHO</h2>
        <p>
          Stichting Otterdam makes furry culture more accessible. Besides
          producing Otterdance, it helps non-furry institutions explor
          anthropomorphic arts through outreach, co-productions, and practical
          support. Its most recent endeavour was the{' '}
          <a href="https://otterdam.art" target="_new">
            Otterdam Furry Arts Festival
          </a>
          , produced in cooperation with{' '}
          <a href="https://nieuweinstituut.nl/" target="_new">
            Nieuwe Instituut{' '}
          </a>
          and subsidised by{' '}
          <a href="https://rotterdam.nl" target="_new">
            Gemeente Rotterdam
          </a>
          .
        </p>
        <p>You can find us at:</p>
        <div className="social">
          <img src={telegram} alt="Telegram" />
          <a href="https://t.me/Otterdance" target="_new">
            <p>@Otterdance</p>
          </a>
        </div>
        <div className="social">
          <img src={bluesky} alt="Bluesky" />
          <a href="https://bsky.app/profile/otterdam.bsky.social" target="_new">
            <p>@otterdam.bsky.social</p>
          </a>
        </div>
        <div className="social">
          <img src={mastodon} alt="Mastodon" />
          <a href="https://mastodon.furrycon.social/@otterdance" target="_new">
            <p>@otterdance@furrycon.social</p>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Infocards;
