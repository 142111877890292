import './Footer.scss';
import otter from './Images/logo-transparent.webp';

const events = [
  { name: 'Otterdance 4', link: 'https://4.otterdance.club' },
  { name: 'Otterdance 3', link: 'https://3.otterdance.club' },
  { name: 'Otterdance 2', link: 'https://2.otterdance.club' },
  { name: 'Otterdance 2022', link: 'https://2022.otterdance.club' },
];

const Footer = () => {
  return (
    <footer>
      <div className="footer">
        <a href="https://otterdam.org" target="_blank" rel="noreferrer">
          <img src={otter} alt="logo Stichting Otterdam" />
        </a>
        <p className="footerTopItem">©2024 Stichting Otterdam</p>
        <p className="footerMidItem">
          {events.map((event, index) => (
            <>
              <a
                href={event.link}
                target="_blank"
                rel="noopener noreferrer"
                key={index}
              >
                {event.name}
              </a>
              {index < events.length - 1 ? ' · ' : ''}
            </>
          ))}
        </p>
        <p className="footerBotItem">
          <a href="https://4.otterdance.club/legal-stuff/">Legal stuff</a>
          {/* pleace replace with a working version of https://otterdance.club/legal-stuff/ asap*/}
        </p>
      </div>
    </footer>
  );
};

export default Footer;
