import './Hero.scss';
import { Countdown } from '@otterdam/otterdam-components';
import danceotter from './Images/otterdance-5-mascot-fullcolour-01-small.webp';

const Hero = () => {
  return (
    <div className="hero">
      <div className="left-header">
        <h1>OTTERDANCE 5: AMSTERDANCE!</h1>
        <h2>
          From{' '}
          <a href="https://otterdam.org" target="_new">
            Stichting Otterdam
          </a>
        </h2>
        <h3>Where</h3>
        <p>
          Akhnaton{' '}
          <a href="https://maps.app.goo.gl/Qc44yJ7MQL6TCEGK9" target="_new">
            (Nieuwezijds Kolk 25, Amsterdam)
          </a>{' '}
          only about 500 meters from Amsterdam Centraal.
        </p>
        <h3>When</h3>
        <p>1900-0100, Saturday, 14 December 2024</p>
        <p>Get your dancing clothes ready and party &apos;till late night!</p>

        {/* Countdown Component */}
        <div className="countdown">
          <Countdown targetDate="2024-12-14T19:00:00">
            <div>
              <h2>The countdown is over!</h2>
            </div>
          </Countdown>
        </div>
        <a
          className="buy-ticket-button"
          href="/tickets"
          rel="noopener noreferrer"
        >
          Buy your tickets now!
        </a>
      </div>

      <div className="right-header">
        <img
          // WARNING: this is a placeholder, please replace!!!
          src={danceotter}
          alt="Dancing Otterdam otter"
        />
      </div>
    </div>
  );
};
export default Hero;
