import React, { useState } from 'react';
import up from './Images/up.svg';
import down from './Images/down.svg';

interface HarmonicaItemProps {
  title: string;
  text: string[];
}

const HarmonicaItem: React.FC<HarmonicaItemProps> = ({ title, text }) => {
  const [isOpen, setIsOpen] = useState<boolean>(true);

  const toggleOpen = (): void => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={`harmonicaItem ${isOpen ? 'open' : ''}`}>
      <div className="harmonicaTitle" onClick={toggleOpen}>
        <h3>{title}</h3>
        <div className="harmonicaArrow">
          <img src={isOpen ? up : down} alt={isOpen ? 'Up' : 'Down'} />
        </div>
      </div>
      {isOpen && (
        <div className="harmonicaContent">
          <ul>
            {text.map((item: string, index: number) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default HarmonicaItem;
