import { useEffect } from 'react';
import './Tips.scss';
import Textbox from '../textbox/Textbox';

const Tips = () => {
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('visible');
          }
        });
      },
      { threshold: 0.1 }
    );

    const tipItems = document.querySelectorAll('.textbox');
    tipItems.forEach((item) => observer.observe(item));

    return () => observer.disconnect();
  }, []);

  return (
    <div className="tips">
      <Textbox
        title="TRAVEL"
        content={[
          `We very strongly advise you to take the train to Amsterdam Centraal. Night trains normally run from Rotterdam Centraal through Amsterdam to Utrecht and back again. <a href="https://www.ns.nl/en" target="_new">Check the NS website</a>, especially for your journey after the event. International trains are at <a href="https://nsinternational.nl/" target="_new">nsinternational.nl</a> and <a href="http://flights.google.com/" target="_new">Google Flights</a> is a good place to start for cheap(er) fares.`,
          ``,
          `<a href="https://flixbus.nl/" target="_new">Flixbus</a> is another option that includes international destinations. It's often cheaper. Search several bus and train lines with <a href="https://wanderu.com/" target="_new">Wanderu</a>.`,
          ``,
          `<a href="https://www.parkopedia.nl/parking/locations/akhnaton_nieuwezijds_kolk_25_amsterdam_north_holland_1012_rd_netherlands_6c3du173zqgf4zs925/?country=nl&arriving=202412141830&leaving=202412140130" target="_new">Parking</a> in the immediate area is extremely expensive, typically costing about €55 for the event. If you drive, you can unload passengers/fursuits/equipment very near the venue.`,
          ``,
          `Inner Amsterdam is one of the world's most popular tourist destinations, especially for “partying” visitors. It's generally safe, however you might encounter obnoxious behaviour.`,
        ]}
      />
      <Textbox
        title="ACCOMMODATION"
        content={[
          `Nearby accommodations are extremely expensive: Hotels typically cost €200-300, and Even a hostel bed is €90.`,
          ``,
          `If you're willing to take the free, all-night ferry across the river, <a href="https://www.clinkhostels.com/clinknoord/"target="_new">ClinkNOORD</a> Hostel is a cheap exception, with beds around €50/night. Otherwise, alternatives include sharing a room or staying outside the city (in a place with good night transit).`,
        ]}
      />
    </div>
  );
};

export default Tips;
